import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import Embed from '../../assets/icons/embed.png';

export default function ShareCharacterModal({aiCharacterId}) {
    const url = window.location.href;
    const trimmedURL = url.replace(/\/(feed\/?)?$/, '');
    const currentURL = `${trimmedURL}/character/${aiCharacterId}`;
    const [copiedUrl, setCopiedUrl] = useState(false);
    const [copiedEmbed, setCopiedEmbed] = useState(false);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(currentURL);
        setCopiedUrl(true);
    }

    const copyEmbedToClipboard = () => {
        navigator.clipboard.writeText(generateEmbedCode());
        setCopiedEmbed(true);
    }

    const generateEmbedCode = () => {
        return `<iframe src="${currentURL}" width="600" height="400" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`;
    };

    return (
        <div className='d-flex flex-column gap-3'>
            <span>Share</span>
            <div className='d-flex flex flex-row'>
                <div className='bg-primary p-1 rounded-circle'>
                    <img
                        src={Embed}
                        style={{ width: "1.5em", height: "1.5em" }}
                        onClick={copyEmbedToClipboard}
                    />
                </div>
            </div>
            <div className='d-flex flex flex-row'>
                <input
                    type="text"
                    value={currentURL}
                    readOnly
                    style={{ width: '100%', padding: '5px' }}
                />
                <Button variant='primary' onClick={copyToClipboard}>Copy</Button>
            </div>
            <div>
                {(copiedUrl || copiedEmbed ) && <span>Link copied to clipboard!</span>}
            </div>
        </div>
    )
}