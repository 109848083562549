import React, { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import Avatar from "../../Component/Comman/Avatar";
import "./aicharacter.css";
import { AIApiInstance } from "../../apis/AIAPI";
import { ProfileTabs } from "./components/ProfileTabs";
import { selectCurrentUser } from "../../Redux/selectors/auth";
import {getAiCharacterImageUrl, getAiCharacterImageUrlV2} from "../../utils/images"
import { Modal } from "../../Component/Modal/Modal";
import NotLoginChatBox from "./components/NotLoginChatBox.js";
//import PaymentMethod from "../../Component/PaymentMethod/PaymentMethod";
// import { authAPIInstance } from "../../apis/AuthAPI";
import SubscribeCharacterModal from "./components/SubscribeCharacterModal";
import { AnalyticsAPIInstance } from "../../apis/AnalyticsAPI";
import { useSearchParams } from "react-router-dom";
import FavoriteIcon from '../../assets/icons/favorite.png';
import NotFavoriteIcon from '../../assets/icons/notFavorite.png';
import Share from '../../assets/icons/share.png';
import ReportAdultModalBody from "./components/ReportAdultModal.jsx";
import ShareCharacterModal from "./components/ShareCharacterModal.jsx";

const customStyles = {
    //check if mobile or web and change the styles

    content: {
        top: '10%',
        left: window.innerWidth <= 760 ? "10%": "260px",
        right: '10%',
        bottom: 'auto',
        marginRight: '10%',
        transform: 'translate(0%, 0%)',
        maxHeight: "100%",
        width: window.innerWidth <= 760 ? "80%": "60%",
    },
};

const subscribeModalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: "80vh",
        maxWidth: "40vw"
    },
};

export default function AICharacter() {
    const { id } = useParams();
    const { state } = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const [character, setCharacter] = useState(() => {
        return state?.character ?? null;
    });
    const [chats, setChats] = useState([]);
    const [groupChats, setGroupChats] = useState([]);
    const [videos, setVideos] =  useState([]);
    const [showNotLoginChatModal, setShowNotLoginChatModal] =  useState(false);
    const [showSubscribeModal, setShowSubscribeModal] =  useState(false);
    const [showReportModal, setshowReportModal] =  useState(false);
    const [showShareModal, setShowShareModal] = useState(false);
    const [views, setViews] =  useState(0);
    const [loading, setLoading] =  useState(false);
    const [isFavorite, setIsFavorite] =  useState(false);
    const [isReported, setIsReported] =  useState(false);
    const [subscription, setSubscription] = useState({ price: 0, subscription_status: false });
    const user = useSelector(selectCurrentUser);

    const selectedChatUserId = searchParams.get('userId');

    useEffect(() => {
        if (!character && id) {
            AIApiInstance.getCharacterInfo(id)
                .then((res) => {
                    setCharacter(res.data.data.data || res.data.data.character);
                })
                .catch(console.error);
            AnalyticsAPIInstance.getCharacterProfileViews(id)
                .then((res) => {
                    setViews(res.data?.data?.views ?? 0);
                })
                .catch(console.error);
        }
    }, [id, character]);

    useEffect(() => {
        if (id) {
            AIApiInstance.getCharacterPublicChatsFromUsers(id)
                .then((res) => {
                    setChats(res.data.data?.publicChats ?? []);
                })
                .catch(console.error);
            AIApiInstance.getGroupChatsByCharacter(id)
                .then((res) => {
                    setGroupChats(res.data.data?.groups ?? []);
                })
                .catch(console.error);
        }
    }, [id]);

    useEffect(() => {
        if (id) {
            AIApiInstance.getCharacterFavoriteStatus(id)
                .then((res) => {
                    setIsFavorite(res.data?.data?.isFavorite);
                })
                .catch(console.error);
        }
    }, [id]);

    useEffect(() => {
        if (id) {
            AIApiInstance.getAllMyAiYoutubeLinks(id)
                .then((res) => {
                    const filteredVideos = (res.data.data.aiYoutubeLinks ?? []).filter((link) => link.characterUUID === id);
                    setVideos(filteredVideos);
                })
                .catch(console.error);
        }
    }, [id]);

    useEffect(() => {
        if (id) {
            setLoading(true);
            AIApiInstance.getCharacterSubscriptionStatus(id)
                .then((res) => {
                    setSubscription(res.data.data);
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [id]);
    
    const handleEditClick = () => {
        navigate("/character/update", { state: character });
    }

    const handleMessageClick = () => {
        //check if logined user
        if (!user) {
            // setShowNotLoginChatModal(true);
            navigate(`/try_ai_chat/${character?._id}`);
            return;
        }
        if (subscription?.price > 0 && !subscription?.subscription_status) {
            const confirm = window.confirm("Do you want to subscribe to this character?");
            if (confirm) {
                if(character?.currency !== 'GEMS') {
                    alert('This currency is not supported now.');
                    return;
                }
                if(user?.gemsCount >= subscription?.price){
                    setShowSubscribeModal(true);
                } else {
                    alert("GEMS balance is not enough, please get more gems to subscribe.");
                }
            }
        } else {
            navigate(`/chat?type=ai&id=${id}`);
        }
    };

    const handleUserSubscription = () => {
        setShowSubscribeModal(false);
        setLoading(true);
        AIApiInstance.createSubscription(character._id)
            .then(() => {
                alert("Subscription created successfully");
                navigate(`/chat?type=ai&id=${id}`);
            })
            .catch((e) => {
                alert(e?.response?.data?.message ?? "Something went wrong.");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // const handlePaymentMethodSucess = (token) => {
    //     setLoading(true);
    //     authAPIInstance
    //         .addPaymentMethod(token)
    //         .then((_) => {
    //             dispatch({
    //                 type: "UPDATE",
    //                 payload: {
    //                     user: {
    //                         key: "stripePaymentMethod",
    //                         value: true
    //                     }
    //                 }
    //             });
    //             alert("Payment method added successfully.");
    //             setShowSubscribeModal(true);
    //         })
    //         .catch((e) => {
    //             console.error(e);
    //             setLoading(false);
    //         });
    //     setShowPaymentMethodModal(false);
    // };

    const getCharacterAvatar = () => {
        if(character?.avatarUrls && character?.avatarUrls?.[0]){
            return character?.avatarUrls[0];
        } else {
            return character?.avatarUrl;
        }
    }

    const getCharacterAvatars = () => {
        if (character?.avatarUrls) {
            return character?.avatarUrls?.map((url) => getAiCharacterImageUrl(url));
        } else {
            return [getAiCharacterImageUrl(character?.avatarUrl)];
        }
    };

    const handleFavoriteClick = () => {
        AIApiInstance.saveCharacterToFavorites(character, !isFavorite)
        .then(_ => {
            setIsFavorite(prev => !prev);
        })
        .catch(console.error)
    }

    const handleShareCharacter = () => {
        setShowShareModal(true);
    }

    const handleReportClick = () => {
        setshowReportModal(true);
    }

    const handleReportSubmit = (status) => {
        if (status) {
            AIApiInstance.reportCharacter(character?._id, true).then((_) => {
                setIsReported((prev) => !prev);
            }).catch(console.error);
        }
        setshowReportModal(false);
    };
    
    return (
        <>
            <div className="view-creator-profile">
                <div className="ai-character-card-container my-0">
                    <Avatar
                        className="ai-character-avatar"
                        avatarUrl={getAiCharacterImageUrlV2(character)}
                        name={character?.characterName}
                    />
                    <div className="ai-character-info">
                        <h4>{character?.characterName}</h4>
                        <span>Views: {views}</span>
                        <p className="ai-character-card-p">
                            {character?.description} {character?.personalitySummary}
                        </p>
                        <div className="d-flex flex-row gap-2 flex-wrap justify-content-center align-items-center">
                            {character?.createdBy === user?.userId && (
                                <Button onClick={handleEditClick} disabled={!character}>
                                    Edit
                                </Button>
                            )}
                            <Button disabled={loading} onClick={handleMessageClick}>
                                {subscription?.price > 0 && !subscription?.subscription_status
                                    ? `${subscription?.price} ${character?.currency ?? "$"}`
                                    : "Message"}
                                {loading && <Spinner className="mx-2" size="sm" />}
                            </Button>
                            <img
                                onClick={handleFavoriteClick}
                                key={isFavorite}
                                src={isFavorite ? FavoriteIcon : NotFavoriteIcon}
                                style={{ width: "2em", height: "2em" }}
                            />
                            <img
                                onClick={handleShareCharacter}
                                src={Share}
                                style={{ width: "1.5em", height: "1.5em" }}
                            />
                            <div onClick={handleReportClick} style={{fontSize:"1.6em"}}>{!isReported ? <i class="fa-regular fa-flag"></i> : <i class="fa-solid fa-flag"></i>}</div>
                        </div>
                    </div>
                </div>
                <ProfileTabs
                    character={character}
                    selectedChatUserId={selectedChatUserId}
                    characterId={character?._id}
                    images={getCharacterAvatars()}
                    chats={chats}
                    groupChats={groupChats}
                    characterName={character?.characterName}
                />
            </div>
            <Modal
                modalIsOpen={showSubscribeModal}
                setIsOpen={setShowSubscribeModal}
                styles={subscribeModalStyles}
                body={
                    <SubscribeCharacterModal
                        name={character?.characterName}
                        avatar={getAiCharacterImageUrl(character?.avatarUrl)}
                        description={character?.description}
                        duration="30 days"
                        price={subscription?.price}
                        currency={character?.currency}
                        onSubmit={handleUserSubscription}
                    />
                }
            />
             <Modal
                modalIsOpen={showReportModal}
                setIsOpen={setshowReportModal}
                styles={subscribeModalStyles}
                body={
                    <ReportAdultModalBody
                        onSubmit={handleReportSubmit}
                    />
                }
            />
            <Modal
                styles={customStyles}
                modalIsOpen={showNotLoginChatModal}
                setIsOpen={setShowNotLoginChatModal}
                shouldCloseOnOverlayClick={false} //disable close on overlay click
                body={
                    <NotLoginChatBox
                        character={character}
                        getCharacterAvatar={() => getAiCharacterImageUrl(getCharacterAvatar())}
                    />
                }
            />
            <Modal
                modalIsOpen={showShareModal}
                setIsOpen={setShowShareModal}
                styles={subscribeModalStyles}
                body={
                    <ShareCharacterModal/>
                }
            />
        </>
    );
}

