import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Route, Routes, useLocation, useParams } from "react-router-dom";
import Header from "../Component/Comman/Header";
import NewModal from "../Component/Comman/NewModal";
import { AuthActions, MainActions } from "../Redux/Actions";
import CreateCharacter from "./AICharacterScreen/CreateCharacter";
import CreateGroupChat from "./Chat/CreateGroupChatScreen";
import Admin from "./Admin/Admin";
import TradingPairs from "./Admin/TradingPairs/TradingPairs";
import ApiPricing from "./ApiPricing/ApiPricing";
import Chat from "./Chat/Chat";
import Explore from "./Explore/Explore";
import CreateStoreScreen from "./MerchantStore/CreateStore/CreateStoreScreen";
import StoreScreen from "./MerchantStore/MerchantStoreScreen";
import SecurityPage from "./SecurityPage/SecurityPage";
// import CreatorMarketplace from "./CreatorMarketplace/CreatorMarketplace";
import { useDetectAdBlock } from "adblock-detect-react";
import { Button, Modal } from "react-bootstrap";
import { readCookie, removeCookie } from "../utils/cookie";
import AICharacter from "./AICharacterScreen/AICharacter";
import UpdateCharacter from "./AICharacterScreen/UpdateCharacter";
import AiSettingsScreen from "./AISettingsScreen/AISettingsScreen";
import ChatTier from "./ChatTier/ChatTier";
import Aptos from "./Aptos/Aptos";
import ComingSoon from "./ComingSoon/ComingSoon";
import CouponScan from "./CouponScan/CouponScan";
import CouponScreen from "./CouponScreen/CouponScreen";
import CouponWallet from "./CouponWallet/CouponWallet";
import AddBrandDeal from "./CreatorMarketplace/CreateBrandDeal/CreateBrandDeal";
import MakeCreatorProfile from "./CreatorMarketplace/CreatorProfile/MakeCreatorProfile";
import ViewCreatorProfile from "./CreatorMarketplace/CreatorProfile/ViewCreatorProfile";
import ViewBrandDeal from "./CreatorMarketplace/ViewBrandDeal/ViewBrandDeal";
import DeveloperPortal from "./DeveloperPortal/DeveloperPortal";
import BankInfoScreen from "./Earnings/BankInfoScreen";
import Earnings from "./Earnings/EarningsScreen";
import Exchange from "./Exchange/Exchange";
import Favorites from "./Followers/Favorites";
import Market from "./Market/Market";
import AddCouponScreen from "./MerchantStore/CouponsScreen/AddCouponScreen";
import CouponsScreen from "./MerchantStore/CouponsScreen/CouponsScreen";
import EditCouponScreen from "./MerchantStore/CouponsScreen/EditCouponScreen";
import EditStoreScreen from "./MerchantStore/CreateStore/EditStoreScreen";
import PublicStoreScreen from "./MerchantStore/PublicStoreScreen";
//import SendPoints from "./MerchantStore/SendPoints/SendPoints";
import StoreStatisticsHistoryScreen from "./MerchantStore/StoreStatisticsHistoryScreen";
import StoreStatisticsScreen from "./MerchantStore/StoreStatisticsScreen";
//import PointExchange from "./PointExchange/PointExchange";
//import TakeOrder from "./PointExchange/TakeOrder";
import WalletPage from "./WalletPage/WalletPage";
import FeedSlider from "./Feed/FeedSlider";

function MainIndex(props) {
    const { modalopen, darkMode, highcontrast, rtlmode } = props.Mainreducer;
    const { activekey, GotoChangeMenu, queries } = props;
    const location  = useLocation()
    const params  = useParams()
    const adBlockDetected = useDetectAdBlock();
    const baseUrl = process.env.REACT_APP_PUBLIC_URL || "/";
    const pair = queries.split("?pair=").length > 1 ? queries.split("?pair=")[1] : undefined;
    const activePath = activekey.split("/")[1];
    const pathWithoutHeaders = ["/documentation", "/create-character", "/my-store/public"];
    const [enabledAdBlockerModal, setEnabledAdBlockerModal] = useState(false);

    const isFeedPage = location.pathname === baseUrl + "feed" || location.pathname === baseUrl;

    useEffect(() => {
        const redirect = readCookie('redirect');
        if (redirect) {
            removeCookie(redirect);
        }
    }, []);

    useEffect(() => {
          /*const token = readCookie('token');
        //later after we ad advertisement
        if (adBlockDetected && token) {
            setEnabledAdBlockerModal(true);
        } */
    }, [adBlockDetected]);

    return (
        <div className="main">
            {pathWithoutHeaders.includes(activekey) || isFeedPage ? (
                ""
            ) : (
                <Header
                    logout={props.logout}
                    onModalOpen={(val) => {
                        props.onModalOpen(true);
                    }}
                    GotoChangeMenu={(val) => {
                        GotoChangeMenu(val);
                    }}
                />
            )}
            <NewModal
                show={modalopen}
                highcontrast={highcontrast}
                darkMode={darkMode}
                onHide={(val) => {
                    props.onModalOpen(false);
                }}
                onChangeDarkMode={() => {
                    props.onChangeDarkMode(darkMode === "dark" ? "light" : "dark");
                }}
                onChangeHighcontrast={() => {
                    props.onChangeHighcontrast(highcontrast === "high-contrast" ? "light" : "high-contrast");
                }}
                onchangeRTLmode={() => {
                    props.onchangeRTLmode(rtlmode === "rtl_mode" ? true : false);
                }}
            />
            <Modal show={enabledAdBlockerModal} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <Modal.Body style={{ textAlign: 'center' }} className="p-4">
                    <i class="fa fa-exclamation-triangle fa-3x p-3 text-danger" aria-hidden="true"></i>
                    <h3>Adblock is detected!</h3>
                    <p>Please consider supporting us by disabling your ad blocker.Kindly reload once you have disabled.</p>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={() => window.location.reload()} style={{ display: 'flex', justifyContent: 'center' }}>
                    Reload
                </Button>
                </Modal.Footer>
            </Modal>
            <div className="body d-flex">
                <Routes>
                    <Route exact path={baseUrl} element={<FeedSlider />} />
                    <Route exact path={baseUrl + "/feed"} element={<FeedSlider />} />
                    <Route exact path={baseUrl + "/explore"} element={<Explore />} />

                    {/* <Route exact path={baseUrl + "/creator-marketplace"} element={<CreatorMarketplace />} /> */}
                    <Route exact path={baseUrl + "/creators-marketplace"} element={<ComingSoon />} />
                    <Route exact path={baseUrl + "/create-brand-deal"} element={<AddBrandDeal />} />
                    <Route exact path={baseUrl + "/edit-profile"} element={<MakeCreatorProfile />} />
                    <Route exact path={baseUrl + "/my-profile"} element={<ViewCreatorProfile />} />
                    <Route exact path={baseUrl + "/view-brand-deal"} element={<ViewBrandDeal />} />
                    {/* <Route exact path={baseUrl + "/"} element={<Dashboard user={props.AuthReducer.user} />} /> */}
                    <Route exact path={baseUrl + "/exchange"} element={<Exchange pair={pair} />} />
                    {/* <Route exact path={baseUrl + "/point-exchange"} element={<PointExchange />} /> */}
                    {/* <Route
                        exact
                        path={baseUrl + "/point-exchange/order/:orderId"}
                        element={<TakeOrder user={props.AuthReducer.user} />}
                    /> */}
                    <Route exact path={baseUrl + "/crypto-market"} element={<Market />} />
                    <Route exact path={baseUrl + "/wallet-page"} element={<WalletPage user={props.AuthReducer.user} />} />
                    <Route exact path={baseUrl + "/admin"} element={<Admin user={props.AuthReducer.user} />} />
                    <Route
                        exact
                        path={baseUrl + "/admin/trading-pairs"}
                        element={<TradingPairs user={props.AuthReducer.user} />}
                    />
                    <Route
                        exact
                        path={baseUrl + "/chat"}
                        element={<Chat user={props.AuthReducer.user}/>}
                    />
                    <Route
                        exact
                        path={baseUrl + "/create-character"}
                        element={<CreateCharacter/>}
                    />
                    <Route
                        exact
                        path={baseUrl + "/create-groupchat"}
                        element={<CreateGroupChat />}
                    />
                    <Route
                        exact
                        path={baseUrl + "/my-store/"}
                        element={<StoreScreen/>}
                    />
                    <Route
                        exact
                        path={baseUrl + "/my-store/create"}
                        element={<CreateStoreScreen/>}
                    />
                    <Route
                        exact
                        path={baseUrl + "/my-store/edit"}
                        element={<EditStoreScreen/>}
                    />
                    <Route
                        exact
                        path={baseUrl + "/my-store/public/:id"}
                        element={<PublicStoreScreen/>}
                    />
                    <Route
                        exact
                        path={baseUrl + "/store/:id/"}
                        element={<PublicStoreScreen/>}
                    />
                    <Route
                        exact
                        path={baseUrl + "/my-store/myStoreStatistics"}
                        element={<StoreStatisticsScreen/>}
                    />
                    <Route
                        exact
                        path={baseUrl + "/my-store/myStoreStatistics-history"}
                        element={<StoreStatisticsHistoryScreen/>}
                    />
                    <Route
                        exact
                        path={baseUrl + "/my-store/add-coupon"}
                        element={<AddCouponScreen/>}
                    />
                    <Route
                        exact
                        path={baseUrl + "/my-store/edit-coupon"}
                        element={<EditCouponScreen/>}
                    />
                    <Route
                        exact
                        path={baseUrl + "/my-store/my-coupons"}
                        element={<CouponsScreen/>}
                    />

                    <Route
                        exact
                        path={baseUrl + "/coupon/:id"}
                        element={<CouponScreen/>}
                    />

                    <Route
                        exact
                        path={baseUrl + "/coupon-wallet"}
                        element={<CouponWallet/>}
                    />

                    <Route
                        exact
                        path={baseUrl + "/coupon-scan"}
                        element={<CouponScan/>}
                    />

                    <Route
                        exact
                        path={baseUrl + "/settings"}
                        element={<SecurityPage/>}
                    />
                    <Route
                        exact
                        path={baseUrl + "/developer-portal"}
                        element={<DeveloperPortal/>}
                        /* element={<ComingSoon/>} */
                    />
                    <Route
                        exact
                        path={baseUrl + "/api-pricing"}
                        element={<ApiPricing/>}
                    />
                    <Route
                        exact
                        path={baseUrl + "/character-settings"}
                        element={<AiSettingsScreen/>}
                    />
                    <Route
                        exact
                        path={baseUrl + "/earnings"}
                        element={<Earnings/>}
                    />
                    <Route
                        exact
                        path={baseUrl + "/bank-info"}
                        element={<BankInfoScreen/>}
                    />
                    <Route
                        exact
                        path={baseUrl + "/character/:id"}
                        element={<AICharacter/>}
                    />
                    <Route
                        exact
                        path={baseUrl + "/character/update"}
                        element={<UpdateCharacter/>}
                    />
                    <Route
                        exact
                        path={baseUrl + "/favorites"}
                        element={<Favorites/>}
                    />
                    <Route
                        exact
                        path={baseUrl + "/premium-price"}
                        element={<ChatTier/>}
                    />
                    <Route
                        exact
                        path={baseUrl + "/aptos-wallet"}
                        element={<Aptos/>}
                    />
                    <Route path={baseUrl + "/:username"} element={<ViewCreatorProfile />} />
                </Routes>
            </div>
        </div>
    );
}

const mapStateToProps = ({ Mainreducer, AuthReducer, ChatReducer }) => ({
    Mainreducer,
    AuthReducer,
    ChatReducer
});

export default connect(mapStateToProps, {
    onModalOpen: MainActions.onModalOpen,
    onChangeDarkMode: MainActions.onChangeDarkMode,
    onChangeHighcontrast: MainActions.onChangeHighcontrast,
    onchangeRTLmode: MainActions.onchangeRTLmode,
    logout: AuthActions.logout
})(MainIndex);
