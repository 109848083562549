import Home from '../../../assets/icons/menu/home.png';
import Marketplace from '../../../assets/icons/menu/marketplace.png';
import Chat from '../../../assets/icons/menu/chat.png';
import Store from '../../../assets/icons/menu/store.png';
import Wallet from '../../../assets/icons/menu/wallet.png';
import Earnings from '../../../assets/icons/menu/earnings.png';
import Developer from '../../../assets/icons/menu/developer_portal.png';
import Pricing from '../../../assets/icons/menu/wgem.png';
import Create from '../../../assets/icons/menu/create.png';
import Explore from '../../../assets/icons/menu/explore.png';

export const menu = {
    "menu": [
        {
            "name": "Feed",
            "routerLink": ["feed"],
            "identifier": "Feed",
            "viewBox": "0 0 22 22",
            "subject": "Feed",
            "isCategory": false,
            "isApp": false,
            "children": [],
            "iconPath": Home
        },
        {
            "name": "Explore",
            "routerLink": ["explore"],
            "identifier": "Explore",
            "viewBox": "0 0 22 22",
            "subject": "Characters & Coupons",
            "isCategory": false,
            "isApp": false,
            "children": [],
            "iconPath": Explore
        },
        {
            "name": "Create",
            "routerLink": ["create-character"],
            "identifier": "Create",
            "viewBox": "0 0 21 20",
            "subject": "Create",
            "isCategory": false,
            "isApp": false,
            "children": [],
            "iconPath": Create
        },
        {
            "name": "Chats",
            "routerLink": ["chat?type=ai"],
            "identifier": "Chat",
            "viewBox": "0 0 21 20",
            "subject": "Chat Messaging",
            "isCategory": false,
            "isApp": false,
            "children": [],
            "iconPath": Chat
        },
        /* {
            "name": "Creators Marketplace",
            "routerLink": ["creators-marketplace"],
            "identifier": "creatorsmarketplace",
            "viewBox": "0 0 22 22",
            "subject": "Characters & Coupons",
            "isCategory": false,
            "isApp": false,
            "children": [],
            "iconPath": Marketplace
        }, */
        /* {
            "name": "My Store",
            "routerLink": ["my-store"],
            "identifier": "mystore",
            "viewBox": "0 0 19 16",
            "subject": "",
            "isCategory": false,
            "isApp": false,
            "children": [],
            "iconPath": Store
        }, */
        {
            "name": "My Wallet",
            "routerLink": ["coupon-wallet"],
            "identifier": "couponwallet",
            "viewBox": "0 0 24 24",
            "subject": "",
            "isCategory": false,
            "isApp": false,
            "children": [],
            "iconPath": Wallet
        },
        // {
        //     "name": "Earnings",
        //     "routerLink": ["earnings"],
        //     "identifier": "earnings",
        //     "viewBox": "0 0 490 490",
        //     "subject": "",
        //     "isCategory": false,
        //     "isApp": false,
        //     "children": [],
        //     "iconPath": Earnings
        // },
        // {
        //     "name": "Pricing",
        //     "routerLink": ["premium-price"],
        //     "identifier": "earnings",
        //     "viewBox": "0 0 490 490",
        //     "subject": "",
        //     "isCategory": false,
        //     "isApp": false,
        //     "children": [],
        //     "iconPath": Pricing
        // },
        // {
        //     "name": "Developer Portal",
        //     "routerLink": ["developer-portal"],
        //     "identifier": "developer",
        //     "viewBox": "0 0 22 22",
        //     "subject": "Developer Portal",
        //     "isCategory": false,
        //     "isApp": false,
        //     "children": [],
        //     "iconPath": Developer
        // }
    ]
}
