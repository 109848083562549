import React, { useState, useEffect } from 'react';
import Home from '../../../assets/icons/menu/home.png'; 
import Chat from '../../../assets/icons/menu/chat.png'; 
import Wallet from '../../../assets/icons/menu/wallet.png';  
import Profile from '../../../assets/icons/menu/profile.png';
import {NavLink } from "react-router-dom";
import "./bottomNav.css";
import { useLocation } from "react-router-dom";

const BottomNavbar = () => {
    const [isVisible, setIsVisible] = useState(true);
    const location  = useLocation();
    const baseUrl = process.env.REACT_APP_PUBLIC_URL || "/";
    const isFeedPage = location.pathname === baseUrl + "feed" || location.pathname === baseUrl;

    console.log("isFeedPage", isFeedPage);

    const handleResize = () => {
        console.log('resizing');
        if(isFeedPage && window.innerWidth < 1024){
            setIsVisible(true);
        }else if (window.innerWidth > 768) {
            setIsVisible(false);
        } else {
            setIsVisible(true);
        }
    }

    useEffect(() => {
        handleResize();
    
        const handleResizeEvent = () => handleResize();
        window.addEventListener('resize', handleResizeEvent);
    
        return () => {
            window.removeEventListener('resize', handleResizeEvent);
        };
    }, []);

    return (
        <div
        className="or-bottom-nav-group btn-group" role="group" aria-label="radio toggle button group"

        style={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            width: '100%',
            backgroundColor: '#000',
            textAlign: 'center',
            padding: '10px',
            display: isVisible ? 'flex' : 'none',
            justifyContent: 'space-around',
            zIndex: 5,
            minWidth: '320px',
        }}>
            <NavLink
                to={'/'}
                className={(navData) => (navData.isActive ? "bottom-nav-icon-active btn" : 'btn')}
                >
                <i class="fa-solid fa-house"></i>
            </NavLink>
            <NavLink
                to={'/explore'}
                className={(navData) => (navData.isActive ? "bottom-nav-icon-active btn" : 'btn')}
                >
                <i class="fa-solid fa-compass"></i>
            </NavLink>
            <NavLink
                to={'/create-character'}
                className={(navData) => (navData.isActive ? "bottom-nav-icon-active btn" : 'btn')}
                >
                <i class="fa-solid fa-circle-plus"></i>
            </NavLink>
            <NavLink
                to={'/chat'}
                className={(navData) => (navData.isActive ? "bottom-nav-icon-active btn" : 'btn')}
                >
                <i class="fa-solid fa-message"></i>
            </NavLink>
            <NavLink
                to={'/my-profile'}
                className={(navData) => (navData.isActive ? "bottom-nav-icon-active btn" : 'btn')}
                >
                <i class="fa-solid fa-user"></i>
            </NavLink>

        </div>
    );
};

export default BottomNavbar;