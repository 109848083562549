import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import orangeLogo from "../../../assets/images/logo/orangeMark.png";
import redchatLogo from "../../../assets/images/logo/redbit.png";
import { getBrowserCurrentUrl } from "../../../utils/getCurrentUrl";
import { selectCurrentUser } from "../../../Redux/selectors/auth";
import { useSelector } from "react-redux";
import { getUserImageUrl } from "../../../utils/images";
import Profile from "../../../assets/images/profile_av.svg";
import { useNavigate } from "react-router-dom";
import './sidebar.css';
import tryStorychat from "../../../assets/icons/try_storychat.png"

function Sidebar(props) {
    const { t } = useTranslation("menu");

    const [isSidebarMini, setIsSidebarMini] = useState(false);
    const [topLogo, setTopLogo] = useState(orangeLogo);
    const baseUrl = process.env.PUBLIC_URL;
    const currentUrl = getBrowserCurrentUrl();
    const user = useSelector(selectCurrentUser);
    const navigate = useNavigate();

    useEffect(() => {
        if (currentUrl.includes("redchat")) {
            setTopLogo(redchatLogo);
        } else {
            setTopLogo(orangeLogo);
        }
    }, [currentUrl]);

    const toggleMenu = () => {
        var sidebar = document.getElementById("mainsidemenu");
                            if (sidebar) {
                                if (sidebar.classList.contains("open")) {
                                    sidebar.classList.remove("open");
                                } else {
                                    sidebar.classList.add("open");
                                }
                            }
    };

    const { activekey, menuData, GotoChangeMenu } = props;

    const getMenuItemClass = (routerLink) => {
        let _activeKey = activekey === '/' ? 'feed': activekey;
        return ("/" + routerLink).includes(_activeKey) ? "active" : "";
    };

    const getMenuIconClass = (routerLink) => {
        let _activeKey = activekey === '/' ? 'feed': activekey;
        return ("/" + routerLink).includes(_activeKey) ? "" : "unselected-icon";
    };

    const getUserAvatar = () => {
        return user?.avatar_url ? getUserImageUrl(user?.avatar_url) : Profile
    }

    return (
        <div id="mainsidemenu" className={`sidebar me-0 border-end poppins-font ${isSidebarMini ? "sidebar-mini" : ""}`}>
            <div className="d-flex flex-column h-100">
                <div className={`p-2 pb-2 m-0 border-bottom align-items-center d-flex flex-row  ${isSidebarMini ? "justify-content-center" : "justify-content-between"}`} style={{}}>
                    <a href={process.env.PUBLIC_URL + "/"} className="mb-0 brand-icon">
                        <span className="logo-text " style={{ color: "var(--bs-orange)" }}>
                        <img className="orangeLogo" src={topLogo} alt="" />
                        </span>
                    </a>
                    <button
                        type="button"
                        className={`sidebar-back-btn ${isSidebarMini ? "sidebar-hidden-back-btn" : ""}`}
                        onClick={() => {setIsSidebarMini(!isSidebarMini);}}
                    >
                        <span><i className={`${isSidebarMini ? "icofont-curved-right" : "icofont-curved-left"}`}></i></span>
                    </button>
                </div>
                <hr className="my-0"></hr>
                <ul className="menu-list flex-grow-1 px-2 py-3 gap-2 d-flex flex-column">
                    {menuData.map((d, i) => {
                        if (d.children.length === 0) {
                            return (
                                <li key={"sidebar-item-" + i} className="collapsed">
                                    <Link
                                        to={"/" + d.routerLink[0]}
                                        onClick={() => {toggleMenu();}}
                                        className={`m-link sidebar-item-container px-2 ${getMenuItemClass(d.routerLink[0])}`}
                                    >
                                        <div className="sidebar-icon">
                                            <img src={d.iconPath} alt="icon" className={getMenuIconClass(d.routerLink[0])} />
                                        </div>
                                        <div className="menu-item-text">
                                            <span className="mb-0">{t(`menu:${d.name.replace(/\s+/g, "_")}`)}</span>
                                        </div>
                                    </Link>
                                </li>
                            );
                        }
                    })}
                </ul>
                <hr></hr>
                <div className="px-4 pb-4">
                    <button className="sidebar-try-btn" onClick={() => navigate("/premium-price")}>
                        <div className="sidebar-try-btn-content">
                            {!isSidebarMini && <span>Try StoryChat</span>}
                            <img src={tryStorychat} style={{marginLeft: isSidebarMini ? "0px" : "10px"}}/> 
                        </div>
                    </button>
                </div>
                <div className={`d-flex flex-start align-items-center gap-2 pb-4 px-4 ${isSidebarMini ? "justify-content-center" : ""}`} onClick={() => navigate("/my-profile")}>
                    <img className="avatar md rounded-circle img-thumbnail" src={getUserAvatar()} alt="profile" />
                    {!isSidebarMini && (
                        <span>{user?.username}</span>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Sidebar;
