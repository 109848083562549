import React, { useEffect, useState } from "react";
import { Dropdown, Nav, Spinner, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getFingerPrint } from "../../utils/fingerPrint";
import AuthRight from "./AuthRight";
import Modal2FA from "./Modal2FA";
import { createCookie } from "../../utils/cookie";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import { AuthActions } from "../../Redux/Actions";
import { config } from "../../utils/config";
import { eventActions, eventCategories, sendFormActionEvent } from '../../utils/googleAnalytics';
import orangeMark from "../../assets/images/logo/orangeMark.png";
import designImage from "../../assets/images/login-signup-collage.png"

function Signin({ login }) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [open2FAModal, setOpen2FAModal] = useState(false);
    const [tempToken, setTempToken] = useState(null);
    const [loading, setloading] = useState(false);

    const {t} = useTranslation('sign_in_page');

    const dispatch = useDispatch();

    const handeLoginError = (error) => {
        setloading(false);
        alert(error?.response?.data?.message ?? "Something went wrong.");
    };

    const handleLogin = async () => {

        try {
            sendFormActionEvent(eventCategories.USER_ACTION, eventActions.form_submit_event, 'login');
            setloading(true);
            const fp = await getFingerPrint();
            const res = await login(password, email, fp, handeLoginError);
            if (res?.message === "TWO_FACTOR_REQUIRED") {
                setloading(false);
                setTempToken(res?.data?.token);
                setOpen2FAModal(true);
                return;
            } else if (res?.data?.token) {
                createCookie("token", res?.data?.token);
                setloading(false);
                dispatch({
                    type: "AUTHENTICATE",
                    payload: {
                        user: res?.data?.user,
                        token: res?.data?.token
                    }
                });
            }
        } catch (error) {
            handeLoginError(error);
        }
    };

    useEffect(() => {
        const messageListener = (event) => {
            const origin = event.origin;
            const target = event.data?.target;
            if (origin === config[config.environment].host && target === "sign-in") {
                createCookie("token",  event.data?.token);
                dispatch(AuthActions.getUserProfile());
                setloading(false);
            }
        };
        window.addEventListener("message", messageListener);
        return () => {
            window.removeEventListener('message', messageListener)
        };
    }, [dispatch]);

    const handleGoogleLogin = () => {
        //todo config url prod and dev
        sendFormActionEvent(eventCategories.USER_ACTION, eventActions.form_submit_event, 'google_login');
        setloading(true);
        window.open(config[config.environment].host+"/api/v1/users/auth/web/google/login");
    }

    function hideModal () {
        setOpen2FAModal(false)
        setTempToken(null);
    }

    const handleEmailChange = (e) => {
        const value = e.target.value;
        sendFormActionEvent(eventCategories.USER_ACTION, eventActions.form_change_event, 'email', value);
        setEmail(value)
    };

    const handlePasswordChange = (e) => {
        const value = e.target.value;
        sendFormActionEvent(eventCategories.USER_ACTION, eventActions.form_change_event, 'password', value);
        setPassword(value)
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>
            {open2FAModal && <Modal2FA show={open2FAModal} onHide={hideModal} tempToken={tempToken}  />}
            <div className="poppins-font bg-black" class="container-sign-in-up">
                <div className="row flex-column-reverse flex-lg-row">
                    <div className="col bg-black">
                        <img class="design-image" src={designImage} alt="" />
                    </div>
                    <div className="col sign-in-half py-4">
                        <div class="sign-container">
                            <img class="logo-image" src={orangeMark} alt="" />
                            <h5 className="fw-bold mt-3 font-md-20">Welcome to StoryChat</h5>
                            <span class="detail-sign-in">Please sign in to your account and start the Journey</span>
                            <div className="d-flex flex-column mt-3">
                                <label className="mt-2" class="label-text">Email</label>
                                <div className="position-relative">
                                    <input
                                        value={email}
                                        type="email"
                                        className="form-control"
                                        onChange={handleEmailChange}
                                        placeholder="Please enter your email"
                                        id="sign-in-up-input"
                                    />
                                </div>
                                <label class="label-text mt-2">Password</label>
                                <div className="position-relative">
                                    <input
                                        type={showPassword ? 'text' : 'password'}
                                        className="form-control"
                                        onChange={handlePasswordChange}
                                        placeholder="Please enter your password"
                                        id="sign-in-up-input"
                                    />
                                    <span className="position-absolute top-50 end-0 translate-middle-y" id="password-toggle-icon" onClick={togglePasswordVisibility}>
                                        <i className={`fa-regular ${showPassword ? 'fa-eye-slash' : 'fa-eye'} pe-3`}></i>
                                    </span>
                                </div>
                                <button
                                    onClick={handleLogin}
                                    type="submit"
                                    disabled={loading}
                                    className="btn violet-gradient-btn py-2 fs-7 w-100 mt-2 fw-bold"
                                >
                                    Log In
                                    {loading && <Spinner className="mx-2" size="sm"/>}
                                </button>
                                <div id="sign-in-or-divider">
                                    <hr></hr>
                                    <span id="sign-in-or-divider-text">or</span>
                                    <hr></hr>
                                </div>
                                <button
                                    onClick={handleGoogleLogin}
                                    type="submit"
                                    disabled={loading}
                                    id="sign-in-google-btn"
                                >
                                    <div id="sign-in-google-btn-content">
                                        <span className="fw-bold">Continue with Google</span>
                                    </div>
                                </button>
                            </div>
                            <AuthRight />
                            <Link
                                to={process.env.PUBLIC_URL + "/forgot-password"}
                                title="#"
                                id="forgot-password-link"
                            >
                                {t("forgotPassword")}
                            </Link>
                            <div class="bottom-sign-in-up-container">
                                <span class="bottom-sign-in-up-label">New on our platform?</span>
                                <Link
                                    to={process.env.PUBLIC_URL + "/sign-up"}
                                    title="#"
                                    class="sign-in-up-link"
                                >
                                    Create an account
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Signin;
