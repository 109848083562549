import axios from "axios";
import { config } from "../utils/config";
import { httpTokenInterceptor } from "./common";
import { readCookie } from "../utils/cookie";

class AIChatAPI {
  constructor() {
    this.axios = axios.create({
      baseURL: config[config.environment].baseApiUrl + "/aichat"
    });
    this.axios.interceptors.request.use(httpTokenInterceptor);
  }

  updateAiChatMessage(messageId, messageContent, imageFile = null) {
    const formData = new FormData();
    formData.append('messageId', messageId);
    formData.append('messageContent', messageContent);

    if (imageFile) {
      formData.append('imageFile', imageFile);
    }

    return this.axios.patch(
      "/assistant/chat_history",
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${readCookie("token")}`
        }
      }
    );
  }
  
  deleteAiChatMessage(messageId) {
    return this.axios.delete(`/assistant/chat_history/${messageId}`, {
      headers: {
        Authorization: `Bearer ${readCookie("token")}`
      }
    });
  }

  updateAiGroupChatMessage(messageId, messageContent, imageFile = null) {
    const formData = new FormData();
    formData.append('messageId', messageId);
    formData.append('groupMessageContent', messageContent);

    if (imageFile) {
      formData.append('imageFile', imageFile);
    }

    return this.axios.patch(
      "/group_chat/chat_history",
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${readCookie("token")}`
        }
      }
    );
  }

  deleteAiGroupChatMessage(messageId) {
    return this.axios.delete(`/group_chat/chat_history/${messageId}`, {
      headers: {
        Authorization: `Bearer ${readCookie("token")}`
      }
    });
  }
}

export const AIChatApiInstance = new AIChatAPI();

export default AIChatAPI;
