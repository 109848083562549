import Slider from "../../Component/Feed/Slider";
import { useState, useEffect, useCallback } from "react";
import { FeedApiInstance } from "../../apis/FeedAPI";
import { Spinner } from "react-bootstrap";
import "./index.css";
import { selectCurrentUser } from "../../Redux/selectors/auth";
import { useSelector } from 'react-redux';
import BottomNav from "../../Component/Comman/BottomNavbar/BottomNav";

export default function FeedSlider() {
    const [feedChats, setFeedChats] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [loading, setLoading] = useState(false);
    const user = useSelector(selectCurrentUser);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [noMoreData, setNoMoreData] = useState(false);

    useEffect(() => {
        setLoading(true);
        const allowAdultContent = user === null ? false : user?.allowAdultContent;
        fetchFeedChats(allowAdultContent, pageNumber);
    }, [user]);

    const fetchFeedChats = useCallback(async (allowAdultContent, pageNum) => {
        try {
            const result = await FeedApiInstance.getFeedChats({
                adultContent: allowAdultContent,
                page: pageNum
            });
    
            if (result.data.data.topCharacterChats.length > 0) {
                setFeedChats((prevChats) => [
                    ...prevChats,
                    ...result.data.data.topCharacterChats
                ]);
            } else {
                setNoMoreData(true);
            }
        } catch (error) {
            console.error('Error fetching feed chats:', error);
        } finally {
            setLoading(false);
        }
    }, []);
    
    const handleNext = () => {
        const nextIndex = currentIndex + 1;
    
        if (nextIndex >= feedChats.length) {
            if (noMoreData) {
                setCurrentIndex(0);
            } else {
                setPageNumber((prevPageNumber) => {
                    const newPageNumber = prevPageNumber + 1;
                    setLoading(true);
                    fetchFeedChats(user ? user.allowAdultContent : false, newPageNumber);
                    return newPageNumber;
                });
            }
        } else {
            setCurrentIndex(nextIndex);
        }
    };

    const handlePrevious = () => {
        const previousIndex = (currentIndex - 1 + feedChats.length) % feedChats.length;
        setCurrentIndex(previousIndex);
    };

    return (
        <div className="d-flex flex-grow-1 bg-black align-items-center justify-content-center feed-slider poppins-font">
            {loading ? <Spinner /> : 
            <div className="d-flex align-items-center justify-content-center flex-row gap-3">
                <button className="feed-next-btn" onClick={handlePrevious}>
                    <i className="icofont-curved-left fs-5"></i>
                </button>
                <Slider data={feedChats[currentIndex]} user={user ? user?.userId : ''} />
                <button className="feed-next-btn" onClick={handleNext}>
                    <i className="icofont-curved-right fs-5"></i>
                </button>
            </div>
        }
        <BottomNav />
        </div>
    )
}