import axios from "axios";
import { config } from "../utils/config";
import { readCookie } from "../utils/cookie";
// import { createFormData } from "../utils/createFormData";
import { httpTokenInterceptor } from "./common";

class FeedAPI {
    constructor() {
        this.axios = axios.create({
            baseURL: config[config.environment].baseApiUrl + "/feedslider"
        });
        this.axios.interceptors.request.use(httpTokenInterceptor);
    }

    getFeedChats({adultContent = false, page}) {
        return this.axios.get(`/get_feed_chats?adultContent=${adultContent}&page=${page}`, {
            headers: {}
        });
    }
}

export const FeedApiInstance = new FeedAPI();

export default FeedAPI;