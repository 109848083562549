import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState, useRef } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { IoTrash } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import { BiSolidImageAdd } from "react-icons/bi";
import { ToastStatus } from "../../Component/Comman/CustomToast";
import { ErrorMsg } from "../../Component/Comman/Typography";
import premadeBotParams from "../../Component/Data/AICharacterData/premadeBotParams";
import { AIApiInstance } from "../../apis/AIAPI";
import { isRedbitUrl } from "../../utils/checkRedbitUrl";
import { getAiCharacterImageUrl } from "../../utils/images";
import imageCompression from 'browser-image-compression';
import { AIFormViewTypes, aiCharacterSchema } from "./common";
import { theme } from "../../utils/config";

const price_currencies = [
    { name: "KRW", text: "Korean Won" },
    { name: "PKR", text: "Pakistani Rupees" },
    { name: "USD", text: "United States Dollar" },
    { name: "PHP", text: "Phillipine Pesos" },
    { name: "VND", text: "Vietnamese Dong" },
];

function UpdateCharacter() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [view, setView] = useState(AIFormViewTypes.CHARACTER);
    //const [schedueledMessages, setSchedueledMessages] = useState([]);
    const [showPrice, setShowPrice] = useState(false);
    const [isRedbit, setIsRedbit] = useState(false);
    const [isDeleting, setDeleting] = useState(false);
    const [toast, setToast] = useState({
        show: false,
        message: "",
        status: ""
    });
    const couponImageInput = useRef(null);

    const {
        register,
        handleSubmit,
        getValues,
        setValue,
        formState: { errors, touchedFields, isSubmitting},
        control
    } = useForm({
        resolver: yupResolver(aiCharacterSchema),
        defaultValues: state,
    });


    const [isAdvance, setIsAdvance] = useState(true);

    useEffect(() => {
        let avatarUrls = getValues('avatarUrls') ?? [];
        if(avatarUrls?.[0] === ''){
            avatarUrls = [];
            setValue('avatarUrls', []);
        }
        const avatarUrl = getValues('avatarUrl');
        if(avatarUrl && avatarUrls?.length === 0){
            avatarUrls.unshift(avatarUrl);
            setValue('avatarUrls', [... avatarUrls]);
        }
    }, [getValues]);

    useEffect(() => {
        if(state?.monetize){
            setShowPrice(true);
        }

        setIsRedbit(isRedbitUrl());
    }, [state._id])

    const onSubmit = async () => {
        try {
            const data = getValues()
            let { _id, createdBy, avatarUrl, coverUrl,  avatarUrls,  characterPrompt, createdAt, __v, hashtags, category, currency, stripePriceId, stripeProductId, ...rest } =
                data;
            rest.isRedbit = isRedbit;

            if(avatarUrls?.length > 0){
                avatarUrls = avatarUrls.filter((image) => {
                    return typeof image!== 'string'
                }).map(image => {
                    return image.file
                });
            }
            // return;
            const formData = new FormData();
            Object.keys(rest).forEach(key => {
                formData.append(key, rest[key]);
            })
            if(avatarUrls?.length > 0){
                avatarUrls.forEach((url) => {
                    formData.append('avatar', url);
                })
            }
            if(coverUrl && typeof coverUrl !== 'string'){
                formData.append('coverUrl', coverUrl);
            }
            if(data.category){
                formData.append('category', data.category);
            }
            if(data.hashtags){
                const hashtagsArray = data.hashtags.split(',').map(tag => tag.trim());
                formData.append('hashtags', JSON.stringify(hashtagsArray));
            }

            await AIApiInstance.updateCharacter(_id, formData);
            alert("Successfully updated character");
            setTimeout(() => {
                navigate("/chat");
            }, 2000);
        } catch (error) {
            alert("Failed to update character");
        }
    };

    /* const addMessage = () => {
        setSchedueledMessages(prev => [...prev, {...defaultSchedueledMessageState}]);
    } */

    const handleDeleteCharacter = async () => {
        try {
            setDeleting(true)

            await AIApiInstance.deleteCharacter(state._id);
            setDeleting(false)

            alert("Successfully deleted character image");

            setTimeout(() => {
                navigate("/chat");
            }, 2000);
        } catch (error) {
            setDeleting(false)

            console.error(error?.message ?? "Error deleting character");

            setToast({
                show: true,
                message: "Could not delete character!",
                status: ToastStatus.ERROR
            });
        }
    };

    // const removeCharacterImage = async () => {
    //     try {
    //         const response = await AIApiInstance.deleteCharacterImage(state._id);
    //         console.log("response", response)
    //         if (response.status === 200) {
    //             setValue("avatarUrl", "");
    //             alert("Successfully deleted character image");
    //         } else {
    //             setToast({
    //                 show: true,
    //                 message: "Could not delete character!",
    //                 status: ToastStatus.ERROR
    //             });
    //         }
            
    //     } catch (error) {
    //         console.error(error?.message ?? "Error deleting character");

    //         setToast({
    //             show: true,
    //             message: "Could not delete character!",
    //             status: ToastStatus.ERROR
    //         });
    //     }
        
    // };
/* 
    const goBack = () => {
        if (schedueledMessages.length > 0) {
            const confirm = window.confirm("Discard Messages?");
            if (confirm) {
                setSchedueledMessages([]);
                setView(AIFormViewTypes.CHARACTER);
            }
        }
        if (schedueledMessages.length === 0) setView(AIFormViewTypes.CHARACTER);
    };

    const handleSubmitMessages = () => {
        const new_messages = [];
        const update_messages = [];
        const formData = new FormData();

        for (let i = 0; i < schedueledMessages.length; i++) {
            const message = schedueledMessages[i];
            const {file, ...rest} = message;
            if(message?._id){
                update_messages.push({ ...rest, ...(file ? { hasFile: true } : {}) });
                file && formData.append("updatedSchedueledMessagesImages", file);
            } else {
                new_messages.push({ ...rest, ...(file ? { hasFile: true } : {}) });
                file && formData.append("schedueledMessagesImages", file);
            }
            if (message.messageText === "") {
                alert("Message text cannot be empty.");
                return;
            }
        }

        formData.append('new_messages', JSON.stringify(new_messages));
        formData.append('update_messages', JSON.stringify(update_messages));

        AIApiInstance.updateSchedueledMessages(state._id, formData)
        .then(_ => {
            alert("Updated successfully.");
            setView(AIFormViewTypes.CHARACTER)
        })
    }; */

    useWatch({
        control,
        name: [
            "avatarUrl",
            "avatarUrls",
            'coverUrl',
            "physicalAppearance",
            "description",
            "likes",
            "personalitySummary",
            "firstMessage",
            "scenario",
            "dialogueSample",
            "category",
            "hashtags",
        ]
    });

    const getCharacterAvatar = (img) => {
        if (img?.file) {
            return img.url;
        }
        return getAiCharacterImageUrl(img);
    };

    const getCharacterCover = () => {
        const cover = getValues('coverUrl');
        if(typeof cover === 'string'){
            return getAiCharacterImageUrl(cover);
        } else {
            return URL.createObjectURL(cover);
        }
    };

    const removeImage = (image) => {
        const avatars = getValues("avatarUrls") ?? [];
        let filtered = [];
        if (typeof image === "string") {
            AIApiInstance.deleteCharacterImage(state?._id, image)
            .then(() => {
                filtered = avatars.filter((_image) => {
                    if (typeof _image === "string") {
                        return image !== _image;
                    }
                    else return true;
                });
            setValue("avatarUrls", filtered);
            }).catch(console.error)
        } else {
            filtered = avatars.filter((_image) => {
                if (typeof _image !== "string") {
                    return image.url !== _image.url;
                }
                else return true;
            });
            setValue("avatarUrls", filtered);
        }
    };

    const removeCoverImage = () => {
        const coverUrl = getValues("coverUrl");
        if (typeof coverUrl === "string") {
            AIApiInstance.deleteCharacterCover(state?._id, coverUrl)
                .then(() => {
                    setValue("coverUrl", null);
                })
                .catch(console.error);
        } else {
            setValue("coverUrl", null);
        }
    };

    const handleImagePicker = () => {
        couponImageInput?.current?.click();
    };

    const compressImage = async (file) => {
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 800,
            useWebWorker: true,
            preserveExif: true,
        };
    
        try {
            const compressedBlob = await imageCompression(file, options);
    
            const compressedFile = new File([compressedBlob], file.name, {
                type: compressedBlob.type,
                lastModified: Date.now(),
            });
    
            return compressedFile;
        } catch (error) {
            console.error('Image compression error:', error);
            throw error;
        }
    };

    const handleImageUpload = async (e) => {
        const file = e.target.files[0];
        if (file) {
            try {
                const compressedFile = await compressImage(file);
    
                console.log('Compressed file type:', compressedFile.type);
                console.log('Compressed file size:', (compressedFile.size / (1024 * 1024)).toFixed(2) + ' MB');
                
                const reader = new FileReader();
                reader.onloadend = () => {
                    const avatars = getValues('avatarUrls') ?? [];
                    setValue('avatarUrls', [...avatars, { file: compressedFile, url: reader.result, new: true }]);
                };
                reader.readAsDataURL(compressedFile);
            } catch (error) {
                console.error('Failed to compress image:', error);
            }
        }
    };

    const handleCoverChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            try {
                const compressedFile = await compressImage(file);
                setValue('coverUrl', compressedFile);
            } catch (error) {
                console.error('Failed to compress cover image:', error);
            }
        }
    };

    return (
        <>
            {view === AIFormViewTypes.CHARACTER ? (
                <div className="p-4">
                    <div>
                        <div>
                            <Header isAdvance={isAdvance} setIsAdvance={setIsAdvance} />
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="container-xxl mt-3 ">
                                    <div className="row mb-3 ">
                                        <div className="col-sm-12 col-md-5 ">
                                            <div className="card p-4 h-100 gap-3">
                                                <div className="col-sm-12 ">
                                                    <Form.Label htmlFor="taxtno200" className="form-label">
                                                        Character Profile
                                                    </Form.Label>

                                                    <div className="d-flex justify-content-center flex-column align-items-center">
                                                        {/* <div style={{ position: 'relative' }}>
                                                {getValues("avatarUrl") && getValues("avatarUrl")?.length > 0 ? (
                                                    <img
                                                    className="avatar xxl rounded img-thumbnail shadow-sm mb-3 object-fit-cover"
                                                    src={getCharacterAvatar()}
                                                    alt="character-avatar"
                                                    />
                                                ) : (
                                                    <div className="avatar xxl rounded img-thumbnail shadow-sm mb-3" />
                                                )}

                                                <Button
                                                    variant="dark"
                                                    className="position-absolute top-0 start-0 ms-2 my-2 rounded px-2 py-1"
                                                    onClick={() => removeCharacterImage()}
                                                >
                                                    <IoTrash size={16} color="white" />
                                                </Button>
                                                </div> */}
                                                        <Carousel
                                                            showThumbs={false}
                                                            className="store_image_container rounded w-100 m-2"
                                                        >
                                                            {[
                                                                ...(getValues("avatarUrls") ? getValues("avatarUrls") : []),
                                                                { type: "upload" }
                                                            ]?.map((image, index) =>
                                                                image?.type !== "upload" ? (
                                                                    <div className="" key={"img" + index}>
                                                                        <div>
                                                                            <Button
                                                                                variant="dark"
                                                                                className="position-absolute top-0 start-0 ms-4 my-2 rounded px-2 py-1"
                                                                                onClick={() => removeImage(image)}
                                                                            >
                                                                                <IoTrash size={16} color="white" />
                                                                            </Button>
                                                                            <div className="couponCarouselImage">
                                                                                <img
                                                                                    src={getCharacterAvatar(image)}
                                                                                    alt="carousel_img"
                                                                                    className="object-fit-cover rounded"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div
                                                                        onClick={handleImagePicker}
                                                                        className="w-100"
                                                                        key={"img-none" + index}
                                                                    >
                                                                        <div className="image_upload_container rounded w-100">
                                                                            <span color={theme.color}>
                                                                                <BiSolidImageAdd
                                                                                    size={28}
                                                                                    className="me-2"
                                                                                />
                                                                                Upload Image
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            )}
                                                        </Carousel>
                                                        <div>
                                                            <Form.Control
                                                                hidden
                                                                type="file"
                                                                className="form-control"
                                                                id="uploadPhoto"
                                                                accept="image/*"
                                                                ref={couponImageInput}
                                                                onChange={handleImageUpload}
                                                            />
                                                            <label htmlFor="uploadPhoto" className="btn btn-primary">
                                                                Upload Photo
                                                            </label>
                                                        </div>
                                                    </div>

                                                    {/* cover */}
                                                    <div className="d-flex justify-content-center flex-column align-items-center my-2">
                                                        {getValues("coverUrl") ? (
                                                            <div className="position-relative">
                                                            <Button
                                                                variant="dark"
                                                                className="position-absolute top-0 start-0 ms-4 my-2 rounded px-2 py-1"
                                                                onClick={() => removeCoverImage()}
                                                            >
                                                                <IoTrash size={16} color="white" />
                                                            </Button>
                                                            <img
                                                                className="avatar xxl rounded img-thumbnail shadow-sm mb-3 object-fit-cover"
                                                                src={getCharacterCover()}
                                                                alt="character-avatar"
                                                            />
                                                            </div>
                                                        ) : (
                                                            <div className="avatar xxl rounded img-thumbnail shadow-sm mb-3" />
                                                        )}
                                                        <div>
                                                            <Form.Control
                                                                // {...register("coverUrl")}
                                                                hidden
                                                                type="file"
                                                                className="form-control"
                                                                id="uploadCover"
                                                                accept="image/*"
                                                                onChange={handleCoverChange}
                                                            />
                                                            <label htmlFor="uploadCover" className="btn btn-primary">
                                                                Upload Cover
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-sm-12 mt-2">
                                                    <Form.Label htmlFor="item100" className="form-label required">
                                                        Character Name
                                                    </Form.Label>
                                                    <Form.Control
                                                        placeholder="Enter character name"
                                                        required
                                                        {...register("characterName")}
                                                        type="text"
                                                        className="form-control"
                                                    />
                                                    {touchedFields.characterName && errors.characterName && (
                                                        <ErrorMsg msg={errors.characterName?.message} />
                                                    )}
                                                </div>

                                                <div className="col-sm-12">
                                                    <Form.Label className="form-label required">Age</Form.Label>
                                                    <Form.Select
                                                        {...register("age")}
                                                        aria-label="Default select example"
                                                        required
                                                    >
                                                        <option value="">--Select Age--</option>
                                                        {Array.from({ length: 81 }, (_, index) => 19 + index).map((age) => (
                                                            <option key={age} value={age}>
                                                                {age}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                    {touchedFields.age && errors.age && (
                                                        <ErrorMsg msg={errors.age?.message} />
                                                    )}
                                                </div>

                                                <div className="col-sm-12">
                                                    <Form.Label className="form-label required">Gender</Form.Label>
                                                    <Form.Select
                                                        {...register("gender")}
                                                        aria-label="Default select example"
                                                        required
                                                    >
                                                        <option value="">--Select Gender--</option>
                                                        <option value="male">Male</option>
                                                        <option value="female">Female</option>
                                                    </Form.Select>
                                                    {touchedFields.gender && errors.gender && (
                                                        <ErrorMsg msg={errors.gender?.message} />
                                                    )}
                                                </div>

                                                <div className="col-sm-12">
                                                    <Form.Label className="form-label required">
                                                        Physical Appearance
                                                    </Form.Label>
                                                    <Form.Select
                                                        {...register("physicalAppearance")}
                                                        onChange={(e) => {
                                                            setValue("physicalAppearance", e.currentTarget.value);
                                                        }}
                                                        aria-label="Default select example"
                                                    >
                                                        <option value={""}>--Select Physical Appearance--</option>
                                                        {premadeBotParams(
                                                            getValues("characterName")
                                                                ? getValues("characterName")
                                                                : "character"
                                                        ).physicalAppearance.map((value, i) => (
                                                            <option key={"appearance-" + i} value={value}>
                                                                {value}
                                                            </option>
                                                        ))}
                                                    </Form.Select>

                                                    {isAdvance && (
                                                        <Form.Control
                                                            value={getValues("physicalAppearance")}
                                                            onChange={(e) => {
                                                                setValue("physicalAppearance", e.currentTarget.value);
                                                            }}
                                                            as="textarea"
                                                            type="text"
                                                            className="form-control mt-3"
                                                            rows={3}
                                                        />
                                                    )}

                                                    {touchedFields.physicalAppearance && errors.physicalAppearance && (
                                                        <ErrorMsg msg={errors.physicalAppearance?.message} />
                                                    )}
                                                </div>

                                                <div className="col-sm-12">
                                                    <Form.Label className="form-label required">Description</Form.Label>
                                                    <Form.Select
                                                        {...register("description")}
                                                        onChange={(e) => {
                                                            setValue("description", e.currentTarget.value);
                                                        }}
                                                        aria-label="Default select example"
                                                    >
                                                        <option value={""}>--Select Description--</option>
                                                        {premadeBotParams(
                                                            getValues("characterName")
                                                                ? getValues("characterName")
                                                                : "character"
                                                        ).description.map((value, i) => (
                                                            <option key={"desc-" + i} value={value} className="text-break">
                                                                {value}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                    {isAdvance && (
                                                        <Form.Control
                                                            value={getValues("description")}
                                                            onChange={(e) => {
                                                                setValue("description", e.currentTarget.value);
                                                            }}
                                                            as="textarea"
                                                            type="text"
                                                            className="form-control mt-3"
                                                            rows={3}
                                                        />
                                                    )}

                                                    {touchedFields.description && errors.description && (
                                                        <ErrorMsg msg={errors.description?.message} />
                                                    )}
                                                </div>

                                                <div className="col-sm-12">
                                                    <Form.Label className="form-label">Category</Form.Label>
                                                    <Form.Select
                                                        {...register("category")}
                                                        onChange={(e) => {
                                                            setValue("category", e.currentTarget.value);
                                                        }}
                                                        aria-label="Default select example"
                                                    >
                                                        <option value={""}>--Select Category--</option>
                                                        {premadeBotParams(
                                                            getValues("characterName")
                                                                ? getValues("characterName")
                                                                : "character"
                                                        ).category.map((value, i) => (
                                                            <option key={"desc-" + i} value={value} className="text-break">
                                                                {value}
                                                            </option>
                                                        ))}
                                                    </Form.Select>

                                                    {touchedFields.description && errors.description && (
                                                        <ErrorMsg msg={errors.description?.message} />
                                                    )}
                                                </div>
                                                <div className="col-sm-12">
                                                    <Form.Label className="form-label">Hashtags</Form.Label>
                                                    <Form.Control
                                                        value={getValues("hashtags")}
                                                        as="textarea"
                                                        type="text"
                                                        className="form-control"
                                                        rows={3}
                                                        onChange={(e) => {
                                                            setValue("hashtags", e.currentTarget.value);
                                                        }}
                                                    />
                                                    {touchedFields.hashtags && errors.hashtags && (
                                                        <ErrorMsg msg={errors.description?.message} />
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-sm-12 col-md-7">
                                            <div className="card p-4 gap-3">
                                                <div className="col-sm-12">
                                                    <Form.Label className="form-label required">
                                                        Personality Summary
                                                    </Form.Label>
                                                    <Form.Select
                                                        {...register("personalitySummary")}
                                                        aria-label="Default select example"
                                                        onChange={(e) => {
                                                            setValue("personalitySummary", e.currentTarget.value);
                                                        }}
                                                    >
                                                        <option value={""}>--Select Personality Summary--</option>
                                                        {premadeBotParams(
                                                            getValues("characterName")
                                                                ? getValues("characterName")
                                                                : "character"
                                                        ).personalitySummary.map((value, i) => (
                                                            <option key={"desc-" + i} value={value}>
                                                                {value}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                    {isAdvance && (
                                                        <Form.Control
                                                            value={getValues("personalitySummary")}
                                                            onChange={(e) => {
                                                                setValue("personalitySummary", e.currentTarget.value);
                                                            }}
                                                            as="textarea"
                                                            type="text"
                                                            className="form-control mt-3"
                                                            rows={3}
                                                        />
                                                    )}
                                                    {touchedFields.personalitySummary && errors.personalitySummary && (
                                                        <ErrorMsg msg={errors.personalitySummary?.message} />
                                                    )}
                                                </div>

                                                <div className="col-sm-12">
                                                    <Form.Label className="form-label required">Likes</Form.Label>
                                                    <Form.Select
                                                        {...register("likes")}
                                                        aria-label="Default select example"
                                                        onChange={(e) => {
                                                            setValue("likes", e.currentTarget.value);
                                                        }}
                                                    >
                                                        <option value={""}>--Select Likes--</option>
                                                        {premadeBotParams(
                                                            getValues("characterName")
                                                                ? getValues("characterName")
                                                                : "character"
                                                        ).likes.map((value, i) => (
                                                            <option key={"desc-" + i} value={value}>
                                                                {value}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                    {isAdvance && (
                                                        <Form.Control
                                                            value={getValues("likes")}
                                                            onChange={(e) => {
                                                                setValue("likes", e.currentTarget.value);
                                                            }}
                                                            as="textarea"
                                                            type="text"
                                                            className="form-control mt-3"
                                                            rows={3}
                                                        />
                                                    )}
                                                    {touchedFields.likes && errors.likes && (
                                                        <ErrorMsg msg={errors.likes?.message} />
                                                    )}
                                                </div>
                                                <div className="col-sm-12">
                                                    <Form.Label className="form-label required">First Message</Form.Label>
                                                    <Form.Select
                                                        {...register("firstMessage")}
                                                        aria-label="Default select example"
                                                        onChange={(e) => {
                                                            setValue("firstMessage", e.currentTarget.value);
                                                        }}
                                                    >
                                                        <option value={""}>--Select First Message--</option>
                                                        {premadeBotParams(
                                                            getValues("characterName")
                                                                ? getValues("characterName")
                                                                : "character"
                                                        ).firstMessage.map((value, i) => (
                                                            <option key={"desc-" + i} value={value}>
                                                                {value}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                    {isAdvance && (
                                                        <Form.Control
                                                            value={getValues("firstMessage")}
                                                            onChange={(e) => {
                                                                setValue("firstMessage", e.currentTarget.value);
                                                            }}
                                                            as="textarea"
                                                            type="text"
                                                            className="form-control mt-3"
                                                            rows={3}
                                                        />
                                                    )}
                                                    {touchedFields.firstMessage && errors.firstMessage && (
                                                        <ErrorMsg msg={errors.firstMessage?.message} />
                                                    )}
                                                </div>

                                                <Form.Check
                                                    type="switch"
                                                    label="Make Character Public"
                                                    {...register("makeCharacterPublic")}
                                                />
                                                {/* <Form.Check
                                                    disabled={true}
                                                    type="switch"
                                                    label="Monetize"
                                                    {...register("monetize")}
                                                    onChange={(e) => {
                                                        setValue("monetize", e.currentTarget.checked);
                                                        setShowPrice(e.currentTarget.checked);
                                                    }}
                                                /> */}
                                                <Form.Check
                                                    type="switch"
                                                    label="Character is adult content? (18+ content will show only on redbit.app)"
                                                    {...register("adultContent")}
                                                    onChange={(e) => {
                                                        setValue("adultContent", e.currentTarget.checked);
                                                    }}
                                                />
                                                <div
                                                    style={{ visibility: showPrice ? "visible" : "hidden" }}
                                                    className="character-price col-sm-12 mt-2"
                                                >
                                                    <Form.Label htmlFor="item100" className="form-label">
                                                        Price
                                                    </Form.Label>
                                                    <Form.Control
                                                        placeholder="Enter Price and Currency"
                                                        required
                                                        disabled={true}
                                                        {...register("price")}
                                                        type="number"
                                                        min={0}
                                                        defaultValue={0}
                                                        className="form-control"
                                                        onChange={(e) => {
                                                            setValue("price", e.currentTarget.value);
                                                        }}
                                                    />
                                                    <Form.Label htmlFor="item100" className="form-label">
                                                        Currency
                                                    </Form.Label>
                                                    <Form.Select
                                                        {...register("currency")}
                                                        aria-label="Default select example"
                                                        onChange={(e) => {
                                                            setValue("currency", e.currentTarget.value);
                                                        }}
                                                        disabled={true}
                                                        defaultValue={"USD"}
                                                    >
                                                        {price_currencies.map((currency, i) => (
                                                            <option value={currency.name} key={i}>
                                                                {currency.text}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                </div>
                                                {/* <Button onClick={handleSchedueledClick}>Schedueled Messages</Button> */}
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="container-xxl">
                                    <div className="row align-items-center ">
                                        <div className="d-flex justify-content-end">
                                            <div>
                                                {handleDeleteCharacter && (
                                                    <Button
                                                        w="full"
                                                        onClick={handleDeleteCharacter}
                                                        style={{ width: "100px" }}
                                                        variant="danger"
                                                        className="text-white"
                                                        disabled={isDeleting || isSubmitting}
                                                    >
                                                        {isDeleting && (
                                                            <Spinner
                                                                size="sm"
                                                                className="me-2"
                                                                animation="border"
                                                                variant="white"
                                                            />
                                                        )}
                                                        Delete
                                                    </Button>
                                                )}
                                                <Button
                                                    onClick={onSubmit}
                                                    disabled={isSubmitting}
                                                    style={{ width: "100px", marginLeft: "10px" }}
                                                >
                                                    {isSubmitting && (
                                                        <Spinner
                                                            animation="border"
                                                            variant="light"
                                                            size="sm"
                                                            className="me-2"
                                                        />
                                                    )}
                                                    Save
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            ) : (
                {/* <SchedueledMessages
                    addMessage={addMessage}
                    schedueledMessages={schedueledMessages}
                    removeSchedueledMessage={removeSchedueledMessage}
                    updateMessage={updateMessage}
                    goBack={goBack}
                    submitMessages={handleSubmitMessages}
                    update={true}
                /> */}
            )}
        </>
    );
}

const Header = ({isAdvance, setIsAdvance}) => (
    <>
        <Link to={process.env.PUBLIC_URL + "/chat"}>
            <div className="d-flex align-items-center mb-4">
                <i className="fa fa-chevron-left me-2 " />
                <p className="mb-0 ">Back</p>
            </div>
        </Link>
        <div className="justify-content-between d-flex w-100">
            <h4>Update Character</h4>

            <Form.Check
                type="switch"
                label="Advanced Mode"
                onChange={() => setIsAdvance((prevState) => !prevState)}
                checked={isAdvance}
            />
        </div>
    </>
);

export default UpdateCharacter;
