import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useMemo, useRef, useState } from 'react';
import { Nav, Tab, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { AuthActions } from '../../Redux/Actions';
import { countryCodes } from '../../utils/countryCodes';
import { getFingerPrint } from '../../utils/fingerPrint';
import { eventActions, eventCategories, sendFormActionEvent } from '../../utils/googleAnalytics';
import AuthRight from './AuthRight';
import orangeMark from "../../assets/images/logo/orangeMark.png";
import designImage from "../../assets/images/login-signup-collage.png";
import "./index.css";

const validationSchema = Yup.object().shape({
    username: Yup.string().required('Username is required.'),
    email: Yup.string().email('Invalid email format.').required('Email is required.'),
    /* phoneNumber: Yup.string().required('Phone number is required.'), */
    verificationCode: Yup.string(),
    password: Yup.string().required('Password is required.'),
    inviteCode: Yup.string(),
});

function Signup({ register }) {
    const {t} = useTranslation('sign_up_page');
    const formRef = useRef();
    const location = useLocation();
    const inviteCode = useMemo(() => {
        if (location.pathname.includes("/sign-up/")) {
            const [, code] = location.pathname.split("/sign-up/");
            return code;
        } else {
            return "";
        }
    }, [location.pathname]);
    const [loading, setloading] = useState(false);

    const errorCb = (e) => {
        alert(e?.response?.data?.message ?? "Something went wrong.");
    }

    /* const handleSendVerificationCode = (phoneNumber) => {
        authAPIInstance
            .sendPhoneVerificationCode(phoneNumber)
            .then((_) => {
                alert("Code sent to your phone.");
            })
            .catch((e) => {
                errorCb(e);
            });
    }; */

    const handleSubmit = async (values) => {
        /* const phoneNumber = `${values.dialCode}${values.phoneNumber}`; */
        /* if (!values?.verificationCode) {
           return handleSendVerificationCode(phoneNumber);
        } */
        setloading(true);
        const fp = await getFingerPrint();
        register(
            values.password,
            values.username,
            values.email,
            fp,
            values.inviteCode,
            errorCb
        );
    }

    const handleFocusEvent = (e) => {
        const fieldName = e.target.name;
        const fieldValue = e.target.value;
        sendFormActionEvent(eventCategories.USER_ACTION, eventActions.form_focus_event, fieldName, fieldValue);
    };

    const handleChangeEvent = (e) => {
        const fieldName = e.target.name;
        const fieldValue = e.target.value;
        sendFormActionEvent(eventCategories.USER_ACTION, eventActions.form_change_event, fieldName, fieldValue);
    };

    const _handleSubmitEvent = () => {
        sendFormActionEvent(eventCategories.USER_ACTION, eventActions.form_submit_event, 'register');
    };

    const initialValues = {
        username: "",
        email: "",
        phoneNumber: "",
        dialCode: countryCodes[0].dial_code,
        verificationCode: "",
        password: "",
        inviteCode: inviteCode ? inviteCode : ""
    };

    return (
        <>
            <div className="poppins-font bg-black" class="container-sign-in-up">
                <div className="row flex-column-reverse flex-lg-row">
                    <div class="col bg-black">
                        <img class="design-image" src={designImage} alt="" />
                    </div>
                    <div class="col sign-in-half py-4">
                        <div class="sign-container">
                            <img class="logo-image" src={orangeMark} alt="" />
                            <h5 className="fw-bold mt-3">Welcome to StoryChat</h5>
                            <span class="detail-sign-in">Please sign up to your account and start the Journey</span>
                            <div className="d-flex flex-column mt-3">
                                <Formik
                                    innerRef={formRef}
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={handleSubmit}>
                                    <Form
                                        onFocus={handleFocusEvent}
                                        onChange={handleChangeEvent}>
                                        <div className="mb-0">
                                            <label class="label-text">{t("username")}</label>
                                            <Field
                                                name="username"
                                                type="text"
                                                className="form-control"
                                                placeholder="Add username here"
                                                id="sign-in-up-input"
                                            />
                                            <ErrorMessage
                                                name="username"
                                                component="div"
                                                className="text-danger"
                                                class="error-message"
                                            />
                                        </div>
                                        <div className="mb-0 mt-2">
                                            <label class="label-text">{t("email")}</label>
                                            <Field
                                                name="email"
                                                type="email"
                                                className="form-control"
                                                placeholder="Add email here"
                                                id="sign-in-up-input"
                                            />
                                            <ErrorMessage
                                                name="email"
                                                component="div"
                                                className="text-danger"
                                                class="error-message"
                                            />
                                        </div>
                                        <div className="mb-0 mt-2">
                                            <label class="label-text">{t("password")}</label>
                                            <Field
                                                name="password"
                                                type="password"
                                                className="form-control"
                                                placeholder="Add Password here"
                                                id="sign-in-up-input"
                                            />
                                            <ErrorMessage
                                                name="password"
                                                component="div"
                                                className="text-danger"
                                                class="error-message"
                                            />
                                        </div>
                                        <div className="mb-0 mt-2">
                                            <label class="label-text">{t("confirmPassword")}</label>
                                            <Field
                                                name="confirmPassword"
                                                type="password"
                                                className="form-control"
                                                placeholder="Add Password here"
                                                id="sign-in-up-input"
                                            />
                                            <ErrorMessage
                                                name="password"
                                                component="div"
                                                className="text-danger"
                                                class="error-message"
                                            />
                                        </div>
                                        <div className="mb-0 mt-2">
                                            <label class="label-text">{t("inviteCode")}</label>
                                            <Field
                                                name="inviteCode"
                                                type="text"
                                                className="form-control mb-3"
                                                placeholder="Username of referrer"
                                                id="sign-in-up-input"
                                            />
                                            <ErrorMessage
                                                name="inviteCode"
                                                component="div"
                                                className="text-danger"
                                                class="error-message"
                                            />
                                        </div>
                                        <button
                                            type="submit"
                                            onClick={_handleSubmitEvent}
                                            className="btn violet-gradient-btn py-2 fs-7 w-100 mt-2 fw-bold"
                                        >
                                            {t("createAccountBtn")}
                                            {loading && <Spinner className="mx-2" size="sm"/>}
                                        </button>
                                    </Form>
                                </Formik>
                                <AuthRight />
                            </div>
                            <div class="bottom-sign-in-up-container">
                                <span class="bottom-sign-in-up-label">Already registered?</span>
                                <Link 
                                    to={process.env.PUBLIC_URL + "/sign-in"} 
                                    title="#" 
                                    className="sign-in-up-link">
                                    {t("logIn")}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = ({ AuthReducer }) => ({
    AuthReducer
});

export default connect(mapStateToProps, {
    register: AuthActions.register
})(Signup);
