import React from "react";
import { Route, Routes } from "react-router-dom";
import { connect } from "react-redux";
import { AuthActions, MainActions } from "../Redux/Actions";
import AuthHeader from "../Component/Comman/AuthHeader";
import Signin from "../Component/Auth/SignIn";
import NewModal from "../Component/Comman/NewModal";
import Signup from "../Component/Auth/Signup";
import ForgotPassword from "../Component/Auth/ForgotPassword";
import { ResetPassword } from "../Component/Auth/ResetPassword";
import Verification from "../Component/Auth/Verification";
import ErrorPage from "../Component/Auth/ErrorPage";
import Market from "../Screen/Market/Market";
import NotLoginChatBox from "./AICharacterScreen/components/NotLoginChatBox";
import { useLocation } from 'react-router-dom';

function AuthIndex(props) {
    const { modalopen, darkMode, highcontrast, rtlmode } = props.Mainreducer;
    const baseUrl = process.env.PUBLIC_URL;
    const location = useLocation();

    const isSignInPage = location.pathname === baseUrl + "/sign-in";
    const isSignUpPage = location.pathname === baseUrl + "/sign-up";

    return (
        <div className={isSignInPage || isSignUpPage ? "new-container-black" : "main py-3 p-xl-4"}>
            {!isSignInPage && !isSignUpPage && (
                <AuthHeader
                    onModalOpen={(val) => {
                        props.onModalOpen(true);
                    }}
                />
            )}
            <NewModal
                show={modalopen}
                highcontrast={highcontrast}
                darkMode={darkMode}
                onHide={(val) => {
                    props.onModalOpen(false);
                }}
                onChangeDarkMode={() => {
                    props.onChangeDarkMode(darkMode === "dark" ? "light" : "dark");
                }}
                onChangeHighcontrast={() => {
                    props.onChangeHighcontrast(highcontrast === "high-contrast" ? "light" : "high-contrast");
                }}
                OnchangeRTLmode={() => {
                    props.OnchangeRTLmode(rtlmode === "rtl_mode" ? true : false);
                }}
            />
            <Routes>
                <Route exact path={baseUrl + "/sign-in"} element={<Signin login={props.login} />} />
                <Route path={baseUrl + "/sign-up"} element={<Signup register={props.register} />} />
                <Route path={baseUrl + "/sign-up/:inviteCode"} element={<Signup register={props.register} />} />
                <Route exact path={baseUrl + "/forgot-password"} element={<ForgotPassword />} />
                <Route exact path={baseUrl + "/reset_password"} element={<ResetPassword />} />
                <Route exact path={baseUrl + "/verification"} element={<Verification />} />
                <Route exact path={baseUrl + "/404page"} element={<ErrorPage />} />
                <Route exact path={baseUrl + "/market-list"} element={<Market />} />
                <Route exact path={baseUrl + "/try_ai_chat/:charId"} element={<NotLoginChatBox />} />
            </Routes>
        </div>
    );
}

const mapStateToProps = ({ Mainreducer, AuthReducer }) => ({
    Mainreducer,
    AuthReducer
});

export default connect(mapStateToProps, {
    onModalOpen: MainActions.onModalOpen,
    register: AuthActions.register,
    login: AuthActions.login
})(AuthIndex);
